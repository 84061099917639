<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="w-50 rounded-xl py-4">
          <v-img contain height="80" class="ma-auto mt-4" src="/img/finpay-black.svg" />
          <v-card-text>
            <v-form ref="resetForm" v-model="valid">
              <v-text-field
                v-model="email"
                :label="$t('changePassword.email')"
                dense
                single-line
                required
                validate-on-blur
                :rules="emailRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" class="px-8" :disabled="submitLoading" :loading="submitLoading" @click="resetPassword"
            rounded>{{ $t('login.changePassword') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <ResetPasswordCodeVerification ref="resetCode" :email="email" />
  </v-container>
</template>
<script>
import Service from '@/services'
import ResetPasswordCodeVerification from './ResetPasswordCodeVerification.vue'
export default {
  components: { ResetPasswordCodeVerification },
  name: 'ResetPassword',
  data () {
    return {
      email: '',
      submitLoading: false,
      valid: true,
      emailRules: [
        (v) => !!v.trim() || this.$t('validation.emailRequired'),
        (v) => /.+@.+/.test(v) || this.$t('validation.incorrectEmailAddress')
      ]
    }
  },
  methods: {
    async resetPassword () {
      this.$refs.resetForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      try {
        await Service.post('/Authentication/GeneratePasswordResetCode', { email: this.email })
        this.$refs.resetCode.show()
      } catch (error) {}
    }
  }
}
</script>
